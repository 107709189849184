<template>
  <div class="box">
    <div class="jbxx">
      <div class="jbtop">
        <div class="icon"></div>
        <div class="tit">基本信息</div>
      </div>
      <el-form ref="form" :model="form" label-width="100px">
        <el-row>
          <el-col :span="7">
            <el-form-item label="姓名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="员工账户">
              <el-input v-model="form.loginName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="员工岗位">
              <!-- <el-input v-model="form.businessRole"></el-input> -->
              <el-select v-model="form.businessRole" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="7">
            <el-form-item label="归属商家">
              <el-input v-model="form.businessName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="账户密码">
              <el-input v-model="form.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="冻结状态">
              <el-input v-model="form.status"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="jbtop" style="margin-top: 10px;">
        <div class="icon"></div>
        <div class="tit">员工分组</div>
      </div>
      <div class="tab">
        <div class="add">
          <el-button type="primary" @click="add" icon="el-icon-circle-plus-outline">选择分组</el-button>
        </div>
        <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">
          <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
          <el-table-column prop="index" label="序号" width="50" align="center">
          </el-table-column>
          <el-table-column prop="name" label="分组名称" align="center">
          </el-table-column>
          <el-table-column prop="name" label="描述" align="center">
          </el-table-column>


          <el-table-column prop=" " label="操作" width="250" align="center">
            <template slot-scope="scope">
              <el-button type="text" style="color: red;">删除</el-button>
            </template>
          </el-table-column>


        </el-table>
      </div>
      <div class="butt">
        <el-button style="width: 140px;height: 50px;" @click="$router.push('/auditBusinessone')">取消</el-button>
        <el-button style="width: 140px;height: 50px;" type="primary" @click="onSubmit">保存</el-button>
      </div>
    </div>
    <el-dialog title="选择分组" :visible.sync="showDialog" width="800px" height="600px">
      <div class="dia">
        <div class="tab">
          <el-table :data="tableData" style="width: 100%" height="calc(100vh - 380px)" border :header-cell-style="{
            'background-color': '#f2f2f2',
            'text-align': 'center',
            border: '1px solid #ddd',
          }">
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column prop="index" label="序号" width="50" align="center">
            </el-table-column>
            <el-table-column prop="name" label="分组名称" align="center">
            </el-table-column>
            <el-table-column prop="name" label="描述" align="center">
            </el-table-column>
          </el-table>
        </div>
        <div class="diafour">
          <el-button type="danger" @click="showDialog = false">取消</el-button>
          <el-button type="primary" @click="showDialog = false">保存 </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {

  data() {
    return {
      form: {
        name: ''

      },
      tableData: [

      ],
      showDialog: false,
      options: [{
        value: '1',
        label: '店长'
      }, {
        value: '2',
        label: '员工'
      }],
      value: '',
      id: ''

    };
  },
  created() {
    console.log(this.$route.query);
    this.id = this.$route.query.id
    var that = this;
    if (this.id) {
      that.$http.post("/businessUser/queryOne", {
        id: that.$route.query.id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.form = response.data.data
        }
      });
    }
  },
  mounted() {

  },
  methods: {
    handleSuccess(response, file, fileList) {
      console.log(response.data.url);
    },
    onSubmit() {
      var that = this;
      if (this.id) {
        that.form.id = this.id
      }
      that.$http.post("/businessUser/saveBusinessUser", that.form).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          console.log(response.data.data);
          that.$router.push('/staff')
        }
      });
      // this.$router.push('/staff')

    },
    add() {
      this.showDialog = true
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  background-color: #fff;
  padding-top: 20px;
  margin-right: 5px;

  .jbxx {
    .jbtop {
      background-color: #eef1f6;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;

      .icon {
        width: 6px;
        height: 19px;
        border-radius: 10px;
        background-color: #f77f1f;
        margin-right: 10px;
      }

      .tit {
        font-weight: 700;
      }
    }

  }

  .butt {
    display: flex;
    justify-content: space-between;
    padding: 50px 300px;
  }

  .tab {
    padding: 20px;
    padding-top: 0;
  }

  .dia {
    .diafour {
      display: flex;
      justify-content: space-between;
      padding: 20px 230px;
    }
  }
}
</style>